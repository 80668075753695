.Wrapper {
  list-style: none;
  box-sizing: border-box;
  padding-left: var(--spacing);
  margin-bottom: -1px;
}
.Wrapper.clone {
  display: inline-block;
  pointer-events: none;
  padding: 0;
  padding-left: 10px;
  padding-top: 5px;
}
.Wrapper.clone .TreeItem {
  --vertical-padding: 5px;

  padding-right: 24px;
  border-radius: 4px;
  box-shadow: 0px 15px 15px 0 rgba(34, 33, 81, 0.1);
}

.Wrapper.ghost.indicator {
  opacity: 1;
  position: relative;
  z-index: 1;
  margin-bottom: -1px;
}
.Wrapper.ghost.indicator .TreeItem {
  position: relative;
  padding: 0;
  height: 8px;
  border-color: #429746;
  background-color: #4ba84f;
}
.Wrapper.ghost.indicator .TreeItem:before {
  position: absolute;
  left: -8px;
  top: -4px;
  display: block;
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #429746;
  background-color: #ffffff;
}

.Wrapper.ghost.indicator .TreeItem > * {
  /* Items are hidden using height and opacity to retain focus */
  opacity: 0;
  height: 0;
}

.Wrapper:not(.indicator) {
  opacity: 0.5;
}

/* .Wrapper .TreeItem > * {
  box-shadow: none;
  background-color: transparent;
} */

.TreeItem {
  position: relative;
  padding: 0.5rem;
  background-color: #fff;
  border: 1px solid #dadce0;
  color: #222;
  border-radius: 0.5rem;
}

.Text {
  flex-grow: 1;
  padding-left: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Count {
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #429746;
  font-size: 0.8rem;
  font-weight: 600;
  color: #fff;
}

.disableInteraction {
  pointer-events: none;
}

.disableSelection .Text,
.clone .Count {
  user-select: none;
  -webkit-user-select: none;
}

.Collapse svg {
  transition: transform 250ms ease;
}

.Collapse.collapsed svg {
  transform: rotate(-90deg);
}
