@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: 50, 145, 55;
    --color-secondary: 0, 0, 0;
    --color-gray: 0, 0, 0;
    --fc-border-color: rgba(0, 0, 0, 0.1);
    --fc-button-bg-color: rgb(50, 145, 55);
    --fc-button-border-color: rgba(50, 145, 55, 0.9);
    --fc-button-text-color: rgb(255, 255, 255);
    --fc-button-active-bg-color: rgba(50, 145, 55, 0.8);
    --fc-button-active-border-color: rgba(50, 145, 55, 0.75);
    --fc-button-text-color: rgb(255, 255, 255);
    --fc-button-hover-bg-color: rgba(50, 145, 55, 0.9);
    --fc-button-hover-border-color: rgba(50, 145, 55, 0.65);
    --fc-button-text-color: rgb(255, 255, 255);
  }

  .pb-100 {
    padding-bottom: 100%;
  }
}

html .Toastify {
  position: relative;
  z-index: 9999;
}

[data-js-focus-visible] :focus:not([data-focus-visible-added]) {
  outline: none;
}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 8px;
  background: rgba(var(--color-secondary), 0.05);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.isResizing {
  background: rgba(var(--color-secondary), 0.1);
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}

html .simplebar-scrollbar {
  cursor: pointer;
}

html .simplebar-scrollbar.simplebar-visible:before {
  opacity: 0.5;
}

html .simplebar-scrollbar::before {
  background-color: rgba(var(--color-primary), 1);
}

html .simplebar-scrollbar.simplebar-visible:hover:before {
  opacity: 0.7;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: rgba(var(--color-primary), 1);
  color: #fff;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: rgba(var(--color-primary), 0.8);
  color: #fff;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: rgba(var(--color-primary), 1);
  color: #fff;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: rgba(var(--color-primary), 0.8);
  color: #fff;
}

html .react-datepicker-popper {
  z-index: 50;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: rgba(var(--color-primary), 0.8);
  color: #fff;
}

.z-1 {
  z-index: 1;
}

html .react-loading-skeleton {
  z-index: 0;
}

html
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: rgba(var(--color-primary), 0.8);
  color: #fff;
}

html
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: rgba(var(--color-primary), 0.8);
  color: #fff;
}

html .react-datepicker__day--keyboard-selected,
html .react-datepicker__month-text--keyboard-selected,
html .react-datepicker__quarter-text--keyboard-selected,
html .react-datepicker__year-text--keyboard-selected {
  background-color: rgba(var(--color-primary), 0.5);
  color: #fff;
}

html .react-datepicker__day--keyboard-selected:hover,
html .react-datepicker__month-text--keyboard-selected:hover,
html .react-datepicker__quarter-text--keyboard-selected:hover,
html .react-datepicker__year-text--keyboard-selected:hover {
  background-color: rgba(var(--color-primary), 0.7);
  color: #fff;
}

html .react-datepicker__day--selected,
html .react-datepicker__day--in-selecting-range,
html .react-datepicker__day--in-range,
html .react-datepicker__month-text--selected,
html .react-datepicker__month-text--in-selecting-range,
html .react-datepicker__month-text--in-range,
html .react-datepicker__quarter-text--selected,
html .react-datepicker__quarter-text--in-selecting-range,
html .react-datepicker__quarter-text--in-range,
html .react-datepicker__year-text--selected,
html .react-datepicker__year-text--in-selecting-range,
html .react-datepicker__year-text--in-range {
  background-color: rgba(var(--color-primary), 0.8);
  color: #fff;
}

html .react-datepicker__day--selected:hover,
html .react-datepicker__day--in-selecting-range:hover,
html .react-datepicker__day--in-range:hover,
html .react-datepicker__month-text--selected:hover,
html .react-datepicker__month-text--in-selecting-range:hover,
html .react-datepicker__month-text--in-range:hover,
html .react-datepicker__quarter-text--selected:hover,
html .react-datepicker__quarter-text--in-selecting-range:hover,
html .react-datepicker__quarter-text--in-range:hover,
html .react-datepicker__year-text--selected:hover,
html .react-datepicker__year-text--in-selecting-range:hover,
html .react-datepicker__year-text--in-range:hover {
  background-color: rgba(var(--color-primary), 1);
  color: #fff;
}

html
  .react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    html .react-datepicker__month-text--in-range,
    html .react-datepicker__quarter-text--in-range,
    html .react-datepicker__year-text--in-range
  ),
html
  .react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    html .react-datepicker__month-text--in-range,
    html .react-datepicker__quarter-text--in-range,
    html .react-datepicker__year-text--in-range
  ),
html
  .react-datepicker__quarter-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    html .react-datepicker__month-text--in-range,
    html .react-datepicker__quarter-text--in-range,
    html .react-datepicker__year-text--in-range
  ),
html
  .react-datepicker__year-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    html .react-datepicker__month-text--in-range,
    html .react-datepicker__quarter-text--in-range,
    html .react-datepicker__year-text--in-range
  ) {
  background-color: rgba(var(--color-primary), 0.5);
  color: #fff;
}

html .react-datepicker__close-icon::after {
  background-color: rgba(var(--color-primary), 0.8);
  color: #fff;
  transition: 0.4s;
}

html .react-datepicker__close-icon:hover::after {
  background-color: rgba(var(--color-primary), 1);
  color: #fff;
}

.icon-calendar {
  background-image: url("./assets/icon-calendar.svg");
  background-repeat: no-repeat;
  background-position: left 0.75rem center;
  background-size: 1rem;
}

html .react-datepicker__navigation {
  top: 8px;
}

@keyframes enter {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-enter {
  animation: enter 0.2s ease-out;
}

@keyframes leave {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(0.9);
    opacity: 0;
  }
}

.animate-leave {
  animation: leave 0.15s ease-in forwards;
}

.button-shipping {
  width: 160px;
  appearance: none;
  position: relative;
  overflow: hidden;
  border: none;
  cursor: pointer;
}

.button-shipping .text {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all 0.2s;
  transform: translateY(20px);
}

.button-shipping .forklift {
  display: none;
  transform: scale(0.4);
  position: absolute;
  top: -2px;
  left: -65px;
  animation: 4s lift-moving infinite;
}

.button-shipping .forklift .upper {
  width: 34px;
  height: 20px;
  margin-bottom: 2px;
  border: 5px solid #ff7745;
  position: relative;
  border-radius: 10px 50px 10px 5px;
}

.button-shipping .forklift .upper:after {
  content: "";
  width: 30px;
  height: 50px;
  position: absolute;
  left: 170%;
  top: 12px;
  box-sizing: border-box;
  border-left: 5px solid #000;
  border-bottom: 6px solid #000;
  border-radius: 0 0 0 5px;
  transform-origin: center;
}

.button-shipping .forklift .lower {
  width: 60px;
  height: 30px;
  position: relative;
  background-color: #ff4500;
  border-radius: 5px 15px 10px 10px;
}

.button-shipping .forklift .lower:before,
.button-shipping .forklift .lower:after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  bottom: -10px;
  border: 3px solid #2b59b5;
  border-radius: 50%;
  background-color: #000;
}

.button-shipping .forklift .lower:after {
  left: 36px;
}

.button-shipping .box {
  display: none;
  width: 50px;
  height: 50px;
  background-color: #ad8762;
  border-radius: 5px;
  position: absolute;
  top: 56px;
  left: 50px;
  transform: scale(0.4);
  animation: 4s box infinite;
}

.button-shipping .box:before,
.button-shipping .box:after {
  content: "";
  width: 4px;
  height: 12px;
  left: 21px;
  background-color: #ffbf59;
  position: absolute;
}

.button-shipping .box:after {
  height: 10px;
  left: 25px;
}

.button-shipping .done svg {
  width: 14px;
  height: 12px;
  display: inline-block;
  fill: none;
  margin: 0 5px 0 0;
  stroke: rgb(7, 147, 7);
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  transition: stroke-dashoffset 0.3s ease;
}

.button-shipping.animate-default .default {
  opacity: 1;
  transform: translateY(0);
}

.button-shipping.animate-default .done {
  transform: translateY(20px);
}

.button-shipping.animate-placing .forklift {
  display: block;
}

.button-shipping.animate-placing .box {
  display: block;
}

.button-shipping.animate-placing .default {
  transform: translateY(-20px);
}

.button-shipping.animate-done .done {
  opacity: 1;
  transform: translateY(0);
}

.button-shipping.animate-done .default {
  transform: translateY(-20px);
}

@keyframes lift-moving {
  0% {
    left: -65px;
  }

  50% {
    left: 20px;
  }

  60% {
    left: 15px;
  }

  100% {
    left: 200px;
  }
}

@keyframes box {
  0% {
    top: 56px;
  }

  20% {
    top: 6px;
    left: 50px;
  }

  50% {
    top: 6px;
    left: 50px;
  }

  60% {
    top: 6px;
    left: 45px;
  }

  100% {
    top: 6px;
    left: 230px;
  }
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }
}

html .react-grid-item > .react-resizable-handle {
  width: 2rem;
  height: 2rem;
  z-index: 5;
}

html .react-grid-item > .react-resizable-handle::after {
  border-right-color: rgba(75, 168, 79, 0.5);
  border-bottom-color: rgba(75, 168, 79, 0.5);
}

html #chartjs-tooltip {
  border-top-left-radius: 0;
}

html .icon-angle-tl {
  content: "";
  position: absolute;
  top: 0;
  left: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 0;
  border-color: transparent rgba(75, 168, 79, 1) transparent transparent;
}

.fc .fc-button {
  text-transform: capitalize;
}

.fc .fc-button:focus {
  box-shadow: rgba(50, 145, 55, 0.25) 0px 0px 0px 0.2rem;
}

.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: rgba(50, 145, 55, 0.5) 0px 0px 0px 0.2rem;
}

.fc .fc-button-primary:focus {
  box-shadow: rgba(50, 145, 55, 0.5) 0px 0px 0px 0.2rem;
}

.fc .fc-toolbar-title {
  font-size: 1.5rem;
}

.fc .fc-col-header-cell-cushion {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-weight: normal;
}

.twitter-picker div:nth-child(1) {
  border-color: transparent transparent #d1d5db !important;
}

.twitter-picker div:nth-child(2) {
  top: -9px !important;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.cell-hover-elements-container {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
}

.cell-hover-elements-container > div {
  flex: 1;
  margin: 2px 3px;
}

.cell-hover-elements-container > div:hover::after {
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f4f9";
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: rgba(var(--color-primary), 1);
  cursor: pointer;
  background-color: rgba(var(--color-primary), 0.1);
  border-radius: 0.375rem;
}

.fc .fc-datagrid-cell-cushion {
  width: 100%;
}

#fullcalendar.fc .fc-header-toolbar {
  margin-top: 0.15rem;
}

@media (min-width: 1280px) {
  #fullcalendar.fc .fc-header-toolbar {
    margin-left: 30rem;
  }
}

#fullcalendar.fc.fc-theme-standard td,
#fullcalendar.fc.fc-theme-standard th {
  font-weight: normal;
}

/* #fullcalendar.fc-theme-standard .fc-scrollgrid {
  border: none;
} */

/* #fullcalendar.fc.fc-theme-standard td:first-child,
#fullcalendar.fc.fc-theme-standard th:first-child {
  border: none;
} */
/* 
#fullcalendar.fc .fc-resource-timeline-divider+th .fc-scroller-harness {
  border: solid 1px rgba(var(--color-gray), 0.1);
} */

#fullcalendar.fc .fc-timeline-overlap-disabled .fc-timeline-event {
  margin: 3px 2px 3px 3px;
  border-radius: 0.375rem;
  border-width: 2px;
}

#fullcalendar.fc .fc-timeline-slot-cushion {
  font-size: 0.875rem;
}
